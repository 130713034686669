#booking-module-tabs {
    min-height: 22em;

    input[type="text"] {
        &[readonly][disabled] {
            border-color: inherit;
            background-color: inherit;
        }
    }

    & button[class~="btn"],
    & input[type="submit"]{
        padding: 6px 9px;
        margin: 25px 0 0 0;
    }

    & [class|="message"] {
        @include font-size(14);
        padding: 10px 0 10px 20px;
        &::before {
            @include font-size(14);
            top:8px;
        }
    }
    & .message-error ~ span{
        color: $darkRed;
        @include font-size(14);
    }
    & .message-warning ~ span{
        color: $orange;
        @include font-size(14);
    }

    .ui-tabs-nav {
        @include shadow-up;
        border: none;
        padding: 0px;
        background: $ultraLightGray;

        li {
            border: none;
            background: transparent;
            border-right: 1px solid $lightGray;

            &:last-child {
                border-right: none;
            }
            .ui-tabs-anchor {
                color: $brandBlue;
                font-size: 24px;
                width:100%;
                padding: .5em;
                text-align: center;

                &:focus,
                &:hover {
                    text-decoration: underline;
                }
            }

            &.ui-tabs-active {
                background: $white;

                .ui-tabs-anchor {
                    color: $darkBlue;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
            &.ui-state-focus {
                .ui-tabs-anchor {
                    text-decoration: underline;
                }
            }
        }
    }

    .ui-tabs-panel {
        margin: 30px 25px 20px 25px;
        padding: 0px;
    }
}

.flightStatusmargin-top {
    margin-top: 32px;
    padding-left: 8px;
}

.checkin-msg {
    padding: 0 15px;
}
