.hero {
    background-color: $gray02;
    height: 594px;
    margin-left: -45px;
    overflow: hidden;
    position: relative;
    width: 1100px;

    img {
        height: 100%;
        width: 100%;
    }
}
#aa-hp-ad-hero {
	& a{
		display: block;
	    height: 100%;
		&:focus {
    		border: 2px solid $brandBlue;
    	}
    }
}

.hero-controls {
    bottom: 0;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;

    & button {
        pointer-events: auto;
    }
}
#hero-prev, #hero-next {
	padding: 0px;
    border: 2px transparent;
    position: absolute;
    width: 45px;
    height: 100%;
    background: none;
    overflow: hidden;
    z-index: 20;

    & img {
    	height:50px;
    	width: 100px;
    	max-width:200%;
	}
	&:hover, &:focus {
		outline : none !important;
		background: $tierEXPDark;
		opacity: 0.6;
    	filter: alpha(opacity=60);
	}
	&:focus {
		border: 2px solid $brandBlue;
		& img{
    		border-top: 2px solid transparent;
    		border-bottom: 2px solid transparent;
    		border-image: linear-gradient(to bottom, $tierEXPDark 0%, $tierEXPDark 100%);
		    border-image-slice: 1;
    	}
	}
}

#hero-prev {
	left: 0;
}

#hero-next {
    right: 0;
    & img {
    	margin-left: -45px;
    }
}
#hero-pause {
    display: inline-block;
    position: absolute;
    top: 325px;
    padding: 0px;
    left: 45px;
    width: 30px;
    height: 30px;
    background: transparent;
    overflow: hidden;
    z-index: 20;
    pointer-events: auto;
    border: none;
    & img {
    	height:60px;
    	width: 30px;

	}
}
.pause {
	& img {
    	margin-top:-30px;
	}
}
