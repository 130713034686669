.reactivate-modal {
    #reactivate-no-thanks-link {
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
        background-color: transparent !important;
        border: 0;
        text-decoration: underline;

        @include respond-to(tablet_up) {
            background: none repeat scroll 0 0 transparent;
            border: medium none;
            color: $linkColor !important;
            cursor: pointer;
            margin-top: 10px;
            padding: 0;
            font-family: $standardFontFamily;
            line-height: $baseLineHeight;
            text-align: right;
            height: 40px;
            width: auto;
            @include font-size($baseFontSize);

            &:focus {
                outline: 1px solid $brandBlue;
            }

            span {
                font-size: 14px;
                padding: 0;
            }
        }
    }
}

.expired-title {
    font-family: $sansFontFamilyLight;
    font-weight: 400;
    font-style: normal;
    font-size: 30px;
    color: $darkBlue;
    line-height: 36px;
}

.reactivate-callout {
    font-family: $sansFontFamilyRegular;
    color: $lightOrange;
    text-rendering: optimizeLegibility;
    line-height: 26px;
    font-size: 19px;
    font-weight: bold;
}

.expired-miles-section {
    margin-top: 25px;
}

.reactivate-link {
    color: $white;
}
