.account-info,
#login {
	padding: 15px;
	height: 100%;
	position: relative;

	& a:focus{
  		outline: 1px solid $white;
  	}
    & .link {
    	margin-top: 0.2em;
        line-height: 22px;
        color: $white;
        text-shadow: none;
        font-family: AmericanSansLight;
    }
    & h2{
    	color: $white;
    	margin-bottom: 0.3em;
    }
    & input{
    	padding: 6px 6px;
    }
    & p,& .btn {
    	margin:0;
    }

}
#login {
	& .customComponent{
    	& label{
    		font-size: 13px;
    		& .lbl {
    			width: 75%;
    		}
    	}
    }
    & .forgot-link-wrapper{
    	position: absolute;
    	font-size:12px;
    	bottom: 15px;
  	    width: 95%;
  	    & a{
  	    	color: $white;
  	    	text-decoration: underline;

  	    	&:focus,
	        &:hover{
	        	text-decoration: none;
	        }
  	    }
	}
	& .remember-submit-wrapper {
		padding-top:10px;
		width: 100%;
	}
	& .forgot-AdvPassword-wrapper{
	        @include font-size-line-height(12);
			margin-bottom: 14px;
      	    width: 95%;
      	    & a{
      	    	color: $white;
      	    	text-decoration: none;

      	    	&:focus,
    	        &:hover{
    	        	text-decoration: underline;
				}
      	    }
    	}
}
.account-info{
	font-family:$sansFontFamilyRegular;

	h2{
		@include head3;
		margin-bottom:0.833em;
	}

	.cardmember-name{
		@include head3;
		font-family:$sansFontFamilyMedium;
		padding-bottom:10px;
	}

	.account-info-links {
	  position: absolute;
	  bottom: 15px;
	  left: 15px;
	  right: 15px;
	}
	  p.delta{
    	padding-bottom:10px;
    }
     p.cardmember-mileage{
     	@include head5;
     	font-family:$sansFontFamilyRegular;
    	padding-bottom:0px;
    	margin-top:20px;
    }
     .creditCardGreeting{
    	padding-bottom:10px;
    }

}

